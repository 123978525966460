const menu = {
    list() {
        return [
    {
        "backMenu":[
            {
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"管理员管理",
                        "menuJump":"列表",
                        "tableName":"users"
                    }
                ],
                "menu":"管理员管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "删除",
                            "修改"
                        ],
                        "menu":"房间类型管理",
                        "menuJump":"列表",
                        "tableName":"dictionaryFangjian"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "删除",
                            "修改"
                        ],
                        "menu":"宴会类型管理",
                        "menuJump":"列表",
                        "tableName":"dictionaryJingdian"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "删除",
                            "修改"
                        ],
                        "menu":"美食类型管理",
                        "menuJump":"列表",
                        "tableName":"dictionaryMeishi"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "删除",
                            "修改"
                        ],
                        "menu":"公告类型管理",
                        "menuJump":"列表",
                        "tableName":"dictionaryNews"
                    }
                ],
                "menu":"基础数据管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"房间信息管理",
                        "menuJump":"列表",
                        "tableName":"fangjian"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "修改",
                            "删除"
                        ],
                        "menu":"房间信息留言管理",
                        "menuJump":"列表",
                        "tableName":"fangjianLiuyan"
                    },
					{
					    "buttons":[
					        "查看",
					        "审核",
					        "删除"
					    ],
					    "menu":"房间预约管理",
					    "menuJump":"列表",
					    "tableName":"fnagjianYuye"
					}
                ],
                "menu":"房间信息管理"
            }
			,{
			    "child":[
			        {
			            "buttons":[
			                "查看",
			                "新增",
			                "修改",
			                "删除"
			            ],
			            "menu":"工作人员管理",
			            "menuJump":"列表",
			            "tableName":"gongzuorenyuan"
			        }
			    ],
			    "menu":"工作人员管理"
			}
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"工作记录管理",
                        "menuJump":"列表",
                        "tableName":"gongzuojilu"
                    }
                ],
                "menu":"工作记录管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"宴会信息管理",
                        "menuJump":"列表",
                        "tableName":"jingdian"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "修改",
                            "删除"
                        ],
                        "menu":"宴会信息留言管理",
                        "menuJump":"列表",
                        "tableName":"jingdianLiuyan"
                    },
					{
					    "buttons":[
					        "查看",
					        "审核",
					        "删除"
					    ],
					    "menu":"宴会预约管理",
					    "menuJump":"列表",
					    "tableName":"jingdianYuye"
					}
                ],
                "menu":"宴会信息管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"美食信息管理",
                        "menuJump":"列表",
                        "tableName":"meishi"
                    }
                    ,
                    {
                        "buttons":[
                            "查看",
                            "修改",
                            "删除"
                        ],
                        "menu":"美食信息留言管理",
                        "menuJump":"列表",
                        "tableName":"meishiLiuyan"
                    },
					{
					    "buttons":[
					        "查看",
					        "审核",
					        "删除"
					    ],
					    "menu":"美食预约管理",
					    "menuJump":"列表",
					    "tableName":"meishiYuye"
					}
                ],
                "menu":"美食信息管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"公告管理",
                        "menuJump":"列表",
                        "tableName":"news"
                    }
                ],
                "menu":"公告管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"用户管理",
                        "menuJump":"列表",
                        "tableName":"yonghu"
                    }
                ],
                "menu":"用户管理"
            }
            ,{
                "child":[
                    {
                        "buttons":[
                            "查看",
                            "新增",
                            "修改",
                            "删除"
                        ],
                        "menu":"轮播图管理",
                        "menuJump":"列表",
                        "tableName":"config"
                    }
                ],
                "menu":"轮播图信息"
            }
        ],
        "frontMenu":[],
        "hasBackLogin":"是",
        "hasBackRegister":"否",
        "hasFrontLogin":"否",
        "hasFrontRegister":"否",
        "roleName":"管理员",
        "tableName":"users"
    },
	{
	    "backMenu":[
	        {
	            "child":[
	                {
	                    "buttons":[
	                        "查看",
	                        "新增",
	                        "修改"
	                    ],
	                    "menu":"房间信息管理",
	                    "menuJump":"列表",
	                    "tableName":"fangjian"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "修改"
	                    ],
	                    "menu":"房间信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"fangjianLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "审核"
					    ],
					    "menu":"房间预约管理",
					    "menuJump":"列表",
					    "tableName":"fnagjianYuye"
					}
	            ],
	            "menu":"房间信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看",
	                        "新增"
	                    ],
	                    "menu":"工作记录管理",
	                    "menuJump":"列表",
	                    "tableName":"gongzuojilu"
	                }
	            ],
	            "menu":"工作记录管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看",
	                        "新增",
	                        "修改"
	                    ],
	                    "menu":"宴会信息管理",
	                    "menuJump":"列表",
	                    "tableName":"jingdian"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "修改"
	                    ],
	                    "menu":"宴会信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"jingdianLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "审核"
					    ],
					    "menu":"宴会预约管理",
					    "menuJump":"列表",
					    "tableName":"jingdianYuye"
					}
	            ],
	            "menu":"宴会信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看",
	                        "新增",
	                        "修改"
	                    ],
	                    "menu":"美食信息管理",
	                    "menuJump":"列表",
	                    "tableName":"meishi"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "修改"
	                    ],
	                    "menu":"美食信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"meishiLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "审核"
					    ],
					    "menu":"美食预约管理",
					    "menuJump":"列表",
					    "tableName":"meishiYuye"
					}
	            ],
	            "menu":"美食信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"公告管理",
	                    "menuJump":"列表",
	                    "tableName":"news"
	                }
	            ],
	            "menu":"公告管理"
	        }
	    ],
	    "frontMenu":[],
	    "hasBackLogin":"是",
	    "hasBackRegister":"否",
	    "hasFrontLogin":"否",
	    "hasFrontRegister":"否",
	    "roleName":"工作人员",
	    "tableName":"gongzuorenyuan"
	},
	{
	    "backMenu":[
	        {
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"房间信息管理",
	                    "menuJump":"列表",
	                    "tableName":"fangjian"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "删除"
	                    ],
	                    "menu":"房间信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"fangjianLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "删除"
					    ],
					    "menu":"房间预约管理",
					    "menuJump":"列表",
					    "tableName":"fnagjianYuye"
					}
	            ],
	            "menu":"房间信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"工作记录管理",
	                    "menuJump":"列表",
	                    "tableName":"gongzuojilu"
	                }
	            ],
	            "menu":"工作记录管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"宴会信息管理",
	                    "menuJump":"列表",
	                    "tableName":"jingdian"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "删除"
	                    ],
	                    "menu":"宴会信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"jingdianLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "删除"
					    ],
					    "menu":"宴会预约管理",
					    "menuJump":"列表",
					    "tableName":"jingdianYuye"
					}
	            ],
	            "menu":"宴会信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"美食信息管理",
	                    "menuJump":"列表",
	                    "tableName":"meishi"
	                }
	                ,
	                {
	                    "buttons":[
	                        "查看",
	                        "删除"
	                    ],
	                    "menu":"美食信息留言管理",
	                    "menuJump":"列表",
	                    "tableName":"meishiLiuyan"
	                },
					{
					    "buttons":[
					        "查看",
					        "删除"
					    ],
					    "menu":"美食预约管理",
					    "menuJump":"列表",
					    "tableName":"meishiYuye"
					}
	            ],
	            "menu":"美食信息管理"
	        }
	        ,{
	            "child":[
	                {
	                    "buttons":[
	                        "查看"
	                    ],
	                    "menu":"公告管理",
	                    "menuJump":"列表",
	                    "tableName":"news"
	                }
	            ],
	            "menu":"公告管理"
	        }
	    ],
	    "frontMenu":[],
	    "hasBackLogin":"是",
	    "hasBackRegister":"否",
	    "hasFrontLogin":"否",
	    "hasFrontRegister":"否",
	    "roleName":"用户",
	    "tableName":"yonghu"
	},
]
    }
}
export default menu;
